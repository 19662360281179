import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { withLocalize } from "react-localize-redux";
import { connect } from "react-redux";
import { renderToStaticMarkup } from "react-dom/server";
import classNames from "classnames";
import Loading from "../components/Loading";
import Skippy from "../components/Skippy";
import Header from "../components/Header";
import Title from "../components/Title";
import translationsCa from "../translations/ca.global.json";
import translationsEs from "../translations/es.global.json";
import translationsEn from "../translations/en.global.json";
//import translationsFr from "../translations/fr.global.json";
import StepsForm from "./StepsForm";

const onMissingTranslation = ({ translationId }) => translationId;

class EditCreate extends Component {
  constructor(props) {
    super(props);
    this.props.initialize({
      languages: ["ca", "es", "en", "fr"],
      translation:
        this.props.lang === "ca"
          ? translationsCa
          : this.props.lang === "es"
          ? translationsEs
          /*: this.props.lang === "fr"
          ? translationsFr*/
          : this.props.lang === "en"
          ? translationsEn
          : translationsCa,
      options: {
        renderToStaticMarkup,
        onMissingTranslation,
        renderInnerHtml: true,
        defaultLanguage: this.props.lang
      }
    });
  }

  render() {
    const { loading, state } = this.props;

    if (loading) return <Loading />;

    let mainClass = classNames("main", {
      "no-steps": state === "P" || state === "N"
    });
  
    return (
      <Fragment>
        <Skippy />
        <header id="header" role="banner" className="fixed-heading">
          <div className="container container--expand heighHeader">
            <Header />
            <Title />
          </div>
        </header>
        <main id="main" className={mainClass} role="main">
          <div className="container container--expand">
            <StepsForm />
          </div>
        </main>
     </Fragment>
    );
  }
}

EditCreate.propTypes = {
  lang: PropTypes.string,
  loading: PropTypes.bool,
  state: PropTypes.string
};

const mapStateToProps = state => {
  return {
    lang: state.context.lang,
    loading: state.loading,
    state: state.form.state
  };
};

export default withLocalize(
  connect(
    mapStateToProps,
    null
  )(EditCreate)
);